import { getWrapper as getNoOpWrapper } from './no-op';

export const contextKey = 'abort';

let _AbortController;
if (typeof window === 'undefined' || typeof window.document === 'undefined') {
	// NodeJS.
	_AbortController = require('abort-controller');
} else if (typeof AbortController !== 'undefined') {
	// Should be all modern browsers.
	_AbortController = AbortController;
}

export const abort = executionContext => {
	const abortContext = executionContext[contextKey];
	if (abortContext && abortContext.controller) {
		abortContext.controller.abort();
	}
};

export const getAbortSignal = executionContext => {
	const abortContext = executionContext[contextKey];
	if (abortContext && abortContext.controller) {
		return abortContext.controller.signal;
	} else {
		return null;
	}
};

export const getWrapper = nextAction => {
	if (!_AbortController) {
		/* We decided not to polyfill this for IE11 to reduce package size, since
		 * every other major browser has built in support. */
		return getNoOpWrapper(nextAction);
	}

	return (fnArgs, executionContext) => {
		executionContext[contextKey].controller = new _AbortController();

		const nextActionPromise = (async () =>
			nextAction(fnArgs, executionContext))();
		
		// This cancel method is used implicitly by `p-timeout` in the Timeout wrapper.
		// See: https://github.com/sindresorhus/p-timeout#ptimeoutinput-milliseconds-fallback
		nextActionPromise.cancel = () => abort(executionContext);

		return nextActionPromise;
	};
};

getWrapper.contextKey = contextKey;
