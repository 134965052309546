import { initOptions } from './options';
import { recordMetric } from './newrelic';
import { FetchHttpError } from '../util/fetch-http-error';
import { getAbortSignal } from './abort';

const validateResponse = async response => {
	if (!response.ok) {
		const { headers } = response;

		// Try to extract an upstream error message.
		let error;
		let description;
		try {
			const contentType = headers.get('content-type');
			if (contentType && contentType.includes('json')) {
				error = await response.json();
				description =
					error.description || error.message || JSON.stringify(error);
			} else {
				description = await response.text();
			}
		} catch (e) {
			/* No-Op */
		}

		throw new FetchHttpError(response, description, error);
	}
};

const defaultOptions = {
	responseBodyType: 'json',
	validateResponse
};

export const defaultIsRetryable = err =>
	!err.status || err.status === 429 || Math.trunc(err.status / 100) === 5;

export const defaultCircuitBreakerErrorFilter = err => !defaultIsRetryable(err);

export const contextKey = 'fetch';

export const getWrapper = (_nextAction, context) => {
	if (!fetch) {
		throw new Error(
			'No globally available `fetch` implementation could be found.'
		);
	}

	initOptions(context, contextKey, defaultOptions);

	return async (fnArgs, executionContext) => {
		const [url] = fnArgs;
		const options = {
			...executionContext[contextKey].options,
			signal: getAbortSignal(executionContext)
		};
		const { responseBodyType } = options;

		recordMetric(executionContext, `${contextKey}.url`, url);

		let response;
		try {
			response = await fetch(url, options);
		} catch (err) {
			if (err.name === 'AbortError') {
				return null;
			} else {
				throw err;
			}
		}

		const { status } = response;
		recordMetric(executionContext, `${contextKey}.status`, response.status);

		// response.json() throws an exception when there is no content.
		if (status === 204) {
			return null;
		}

		await options.validateResponse(response);
		return response[responseBodyType]();
	};
};

getWrapper.contextKey = contextKey;
