import asyncRetry from 'async-retry';
import { recordMetric } from './newrelic';
import { initOptions } from './options';

export const contextKey = 'retry';

// See: https://github.com/zeit/async-retry
const defaultOptions = {
	retries: 3,
	minTimeout: 30,
	isRetryable: _err => true
};

export const getWrapper = (nextAction, context) => {
	initOptions(context, contextKey, defaultOptions);
	return async (fnArgs, executionContext) => {
		let attempts = 0;
		try {
			return await asyncRetry(async (bail, attemptNum) => {
				attempts = attemptNum;
				try {
					return await nextAction(fnArgs, executionContext);
				} catch (e) {
					const { isRetryable } = executionContext[contextKey].options;
					if (!(await isRetryable(e))) {
						bail(e);
						return null;
					}
					throw e;
				}
			}, executionContext[contextKey].options);
		} finally {
			executionContext[contextKey].attempts = attempts;
			recordMetric(executionContext, `${contextKey}.attempts`, attempts);
		}
	};
};

getWrapper.contextKey = contextKey;
